import { merge } from 'lodash';

import Paper from './Paper';
import Button from './Button';
import Divider from './Divider';
import Typography from './Typography';
import CssBaseline from './CssBaseline';
import Chip from './Chip';
import OutlinedInput from './OutlinedInput';
import Tabs from './Tabs';
import Checkbox from './Checkbox';
import Radio from './Radio';
import InputLabel from './InputLabel';
import FormHelperText from './FormHelperText';
import Tooltip from './Tooltip';
import Menu from './Menu';
import IconButton from './IconButton';
import LoadingButton from './LoadingButton';
import ToggleButtonGroup from './ToggleButtonGroup';
import DataGrid from './DataGrid';
import Skeleton from './Skeleton';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return merge(
    Paper(theme),
    Button(theme),
    Divider(theme),
    Typography(theme),
    CssBaseline(theme),
    Chip(theme),
    OutlinedInput(theme),
    Tabs(theme),
    Checkbox(theme),
    Radio(theme),
    InputLabel(theme),
    FormHelperText(theme),
    Tooltip(theme),
    Menu(theme),
    IconButton(theme),
    LoadingButton(theme),
    ToggleButtonGroup(theme),
    DataGrid(theme),
    Skeleton(theme),
  );
}
