import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ChecklistReasonsMultipleSelect, Popup } from '@components';
import {
  ChecklistItemLocal as ChecklistItem,
  PolicyListReasonsTypeEnum,
  PopupTypeEnum,
} from '@interfaces';
import { useChecklistApproveModal } from './controller';

interface IChecklistApproveModal {
  onClose: () => void;
  onApproveClose: () => void;
  open: boolean;
  isRequiredReasons: boolean;
  drawRequestId?: string;
  popupText?: string;
  checklistItems: ChecklistItem[];
  projectId: string;
  checklistId?: string;
  source: string;
  checklistType: PolicyListReasonsTypeEnum;
  isMultiSelect?: boolean;
  defaultReasons?: string[];
}

const ChecklistApproveModal: FC<IChecklistApproveModal> = ({
  onClose,
  onApproveClose,
  open,
  checklistItems,
  drawRequestId,
  isRequiredReasons,
  popupText,
  projectId,
  checklistId,
  source,
  checklistType,
  isMultiSelect = true,
  defaultReasons = [],
}) => {
  const { setChecklistReasons, onApprove, reasonsList, isReasonError } = useChecklistApproveModal({
    checklistItems,
    drawRequestId,
    projectId,
    onApproveClose,
    isRequiredReasons,
    checklistId,
    checklistType,
  });

  return (
    <Popup
      open={open}
      type={PopupTypeEnum.CONFIRMATION}
      title="Confirmation"
      text={
        popupText ||
        'Are you sure you want to proceed with the exceptions to the following requirements?'
      }
    >
      <Stack sx={{ width: '100%' }}>
        {checklistItems?.map((item, index) => (
          <Stack pt="1.5rem" key={item.id}>
            <Typography variant="body2" sx={{ mb: '0.5rem' }}>
              {`${item.label} ${isRequiredReasons ? '' : ' (optional)'}`}
            </Typography>
            <ChecklistReasonsMultipleSelect
              isRequired={isRequiredReasons}
              reasonsList={reasonsList}
              setChecklistReasons={setChecklistReasons}
              checklistItem={item}
              shouldValidate={isReasonError}
              source={`${source}__select__index_${index}`}
              defaultReasons={defaultReasons}
              isMultiSelect={isMultiSelect}
            />
          </Stack>
        ))}
        <Stack direction="row" justifyContent="flex-end" alignItems="center" mt="3.75rem">
          <Button variant="text" onClick={onClose} data-cy={`${source}__cancel__button`}>
            Cancel
          </Button>
          <Button onClick={onApprove} sx={{ ml: '0.5rem' }} data-cy={`${source}__confirm__button`}>
            Confirm
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ChecklistApproveModal;
