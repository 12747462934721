import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { IPropertyDetailLocal } from '@interfaces';
import { colors } from '@theme';
import { LabelAndValue } from '@components';

const ProjectProperty: FC<{ projectProperty: IPropertyDetailLocal[] }> = ({ projectProperty }) => (
  <Stack>
    <Stack direction="row" justifyContent="flex-end" sx={{ pb: '0.5rem' }}>
      <Typography
        variant="labelSemiBold"
        sx={{ width: '70px', textAlign: 'center', ml: '15px', color: colors.text.medium }}
      >
        Existing
      </Typography>
      ➝
      <Typography
        variant="labelSemiBold"
        sx={{ width: '70px', textAlign: 'right', color: colors.text.medium }}
      >
        Proposed
      </Typography>
    </Stack>
    {projectProperty?.map((room, index) => (
      <LabelAndValue key={room.name} label={room.name as string} justifyContent="space-between">
        <Stack direction="row" justifyContent="flex-end">
          <Typography
            variant="body3SemiBold"
            sx={{
              width: '70px',
              textAlign: 'center',
              ml: '15px',
              color: colors.text.medium,
            }}
            data-cy={`project__overview__property_detail_existing__value__index_${index}`}
          >
            {room.existing_value}
          </Typography>
          ➝
          <Typography
            variant="body3SemiBold"
            sx={{ width: '70px', textAlign: 'center', color: colors.text.medium }}
            data-cy={`project__overview__property_detail_proposed__value__index_${index}`}
          >
            {room.proposed_value}
          </Typography>
        </Stack>
      </LabelAndValue>
    ))}
  </Stack>
);

export default ProjectProperty;
