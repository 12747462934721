import { Box, Divider, Typography } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { ControllerInterface } from '../controller';
import { AuditLogItem } from '@components';
import { colors } from '@theme';

export const AuditLog: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { auditLog } = controller;
  return (
    <>
      <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
        <>
          <Typography variant="printH2" data-cy="report_disbursement_section_title">
            Disbursement of Funds Certification
          </Typography>
          <Typography pb={2} variant="printBody1">
            The Construction Department has reviewed the above referenced Disbursement Request and
            Disbursement Agreement, to the extent necessary to understand the defined terms
            contained herein and in the Disbursement Request that are incorporated by reference from
            the Disbursement Agreement and to provide the certification contained herein.
          </Typography>
          <Typography pb={2} variant="printBody1">
            "Construction Coordinator" hereby confirms, to the best of its knowledge as of the date
            hereof, that it believes that, with respect to that portion of the Project for which the
            borrowing entity has requested a draw of funds against progress of construction, that in
            its reasonable estimate, meets the loan agreement terms, department policy, and has
            provided justification for approval that meet the minimum policy approval guidelines.
          </Typography>
          <Typography variant="printBody1">
            "Construction Coordinator" hereby certifies that, to its knowledge, solely with respect
            to that portion of the Project for which a draw of funds has been requested and
            presented for review, not withstanding unknown costs, scope changes, or any and all
            unrevealed factors of issue, that funds being approved and disbursed are within an
            acceptable variance of the project progress to date, and an inspection of all supportive
            elements, inclusive but not limited to documentation, site progress, entitlement
            progress, material storage, material acquisition, trade deposits, or other not listed
            here meets the minimum threshold for an approval to release funds.
          </Typography>
        </>
      </Box>
      <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
        <Typography variant="printH2" data-cy="report_auditlog_section_title">
          Draw Audit log
        </Typography>
        {auditLog?.map((item, index) => {
          return (
            <Fragment key={index.toString()}>
              <AuditLogItem showFullMessage item={item} source="report__audit_log" index={index} />
              <Divider sx={{ mt: '16px', mb: '24px', color: colors.neutral.lighter }} />
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};
