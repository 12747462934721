import React, { FC, PropsWithChildren } from 'react';
import { EngineProvider } from '@context';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
const Content = styled('main')({
  flexGrow: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const ContentWithEngine: FC<PropsWithChildren> = ({ children }) => {
  return <Content>{children}</Content>;
};

export const ContentBody: FC<PropsWithChildren> = ({ children }) => (
  <EngineProvider>
    <ContentWithEngine>{children}</ContentWithEngine>
  </EngineProvider>
);
