import { Box, Divider, Typography } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { ControllerInterface } from '../controller';
import { colors } from '@theme';
import { CommentHeader } from '@components';

export const Comments: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { comments } = controller;

  if (!comments.length) return null;

  return (
    <Box sx={{ breakInside: 'avoid' }} pr="15px" pl="15px">
      <Typography variant="printH2" data-cy="report_comments_section_title">
        Comments
      </Typography>
      {comments?.map((comment, index) => {
        if (!comment.message) return null;
        return (
          <Fragment key={comment.id}>
            <CommentHeader comment={comment} source="report__comments" index={index} />
            <Typography
              dangerouslySetInnerHTML={{ __html: comment.message }}
              variant="body2"
              sx={{ pt: '8px', wordWrap: 'break-word' }}
            />
            <Divider sx={{ mt: '8px', mb: '16px', color: colors.neutral.lighter }} />
          </Fragment>
        );
      })}
    </Box>
  );
};
